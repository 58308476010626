import { Controller } from "stimulus";
// import consumer from "channels/consumer";

export default class extends Controller {
  static targets = [ "status", "action" ]

  initialize() {
    this.subscription = App.cable.subscriptions.create(
      {
        channel: "VideoConversionChannel",
        id: this.element.dataset.id,
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    this.updateProgress(data * 100)
  }

  updateProgress(progress) {
    let progressPercent = ''
    if (progress >= 100) {
      progressPercent = "100%"
    } else {
      progressPercent = Math.round(progress) + "%"
    }
    this.statusTarget.innerHTML = progressPercent

    if(progress >= 100) {
      this.statusTarget.innerHTML = 'Ready'
      this.actionTarget.classList.remove('d-none')
    }
  }
}