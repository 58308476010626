import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "video", "infoMarkerInput", "workingTimeMarkerInput" ]

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
    console.log(this.videoTarget, this.infoMarkerInputTarget, this.workingTimeMarkerInputTarget)
  }

  setInfoMarker() {
    this.infoMarkerInputTarget.value = this.videoTarget.currentTime
  }

  previewInfoMarker() {
    this.videoTarget.currentTime = this.infoMarkerInputTarget.value
  }

  setWorkingTimeMarker() {
    this.workingTimeMarkerInputTarget.value = this.videoTarget.currentTime
  }

  previewWorkingTimeMarker() {
    this.videoTarget.currentTime = this.workingTimeMarkerInputTarget.value
  }
}
