import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    sliderMin: Number,
    sliderMax: Number,
    sliderFrom: Number,
    sliderTo: Number,

    // workingTimeStartsAtIndex: Number,
    // workingTimeEndsAtIndex: Number,
  }
  static targets = [ "form", "slider" ]

  connect() {
    console.log('admin/chart_with_slider_controller')
    // return
    const chart = Chartkick.charts["chart-1"]
    console.log(chart)
    // return

    setTimeout(function() {
      if (chart.getAdapter() == "chartjs") {
        let chartConfig = chart.getChartObject().config
        chartConfig.options.scales.x.afterUpdate = function() {
          console.log("after update")
          this.chartLoaded()
        }.bind(this)
        // chartConfig.options.onClick = function (event, native, active) {
        //   if (native.length > 0) {
        //     let xAxisIndex = native[0]._index
        //     console.log("clicked on x-axis column", xAxisIndex)
        //   }
        // }
        chart.getChartObject().update(chartConfig)
      }
    }.bind(this), 1000)
    console.log(chart)

    if (!this.hasSliderTarget) {
      return
    }

    $(this.sliderTarget).ionRangeSlider({
      min: this.sliderMinValue,
      max: this.sliderMaxValue,
      type: 'double',
      // step: 50,
      prettify: false,
      hasGrid: true,
      from: this.sliderFromValue,
      to: this.sliderToValue,
      onChange: function(data) {
        //this.sliderOnChange(data)
      }.bind(this),
      onFinish: function(data) {
        // $.rails.fire(obj.input[0], 'change')
        //this.sliderOnFinish(data)
      }.bind(this)
    })
  }

  chartLoaded() {
    if (!this.hasFormTarget) {
      return
    }

    console.log('chartLoaded')
    const chart = Chartkick.charts["chart-1"].chart

    const data = chart.getDatasetMeta(0).data;
    const firstPoint = data[0]
    const lastPoint = data[data.length - 1]
    console.log('firstPoint', firstPoint)
    console.log('lastPoint', lastPoint)

    $(this.formTarget).css({
      marginLeft: firstPoint.x - 8,
      width: lastPoint.x - firstPoint.x + 16
    })
  }

  // sliderOnChange(data) {
  //   console.log("sliderOnChange", data)
  // }
  //
  // sliderOnFinish(data) {
  //   console.log("sliderOnFinish", data)
  // }
}
