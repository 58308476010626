import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    timeout: Number
  }

  connect() {
    console.log("broadcast player connected", this.urlValue)
    if (this.hasTimeoutValue) {
      setTimeout(function() {
        console.log("Timeout")
        this.next()
      }.bind(this), this.timeoutValue)
    }
  }

  next() {
    $.rails.ajax({
      url: this.urlValue,
      dataType: "script"
    })
  }
}
