import { Controller } from "stimulus"
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

export default class extends Controller {
  static values = {
    data: Array,

    sliderMax: Number,
    sliderFrom: Number,
    sliderTo: Number,

    workingTimeStartsAtIndex: Number,
    workingTimeEndsAtIndex: Number,
  }
  static targets = [ "canvas", "form", "slider" ]

  connect() {
    console.log('admin/chart controller')
    // console.log(this.dataValue)

    const ctx = this.canvasTarget.getContext('2d');

    let labels = []
    for (let i = 0; i < this.dataValue.length; i++) {
      labels.push(i)
    }
    // console.log("this.veldDataValue", this.veldDataValue)

    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'hMSL',
            data: this.dataValue,
            fill: true,
            borderColor: 'rgba(124, 181, 236, 0.5)',
            borderWidth: 1,
            backgroundColor: 'rgba(124, 181, 236, 0.5)',
            yAxisID: 'y',
            parsing: {
              yAxisKey: 'hmsl'
            },
          },
          {
            label: 'Working Time hMSL',
            data: this.dataValue,
            fill: false,
            borderColor: 'rgba(124, 181, 236, 1)',
            borderWidth: 1,
            yAxisID: 'y',
            parsing: {
              yAxisKey: 'wt_hmsl'
            },
          },
          {
            label: 'velD',
            data: this.dataValue,
            fill: false,
            borderColor: 'rgba(67, 67, 72, 0.2)',
            borderWidth: 1,
            yAxisID: 'y1',
            parsing: {
              yAxisKey: 'veld'
            }
          },
          {
            label: 'Working Time velD',
            data: this.dataValue,
            fill: false,
            borderColor: 'rgba(67, 67, 72, 0.2)',
            borderWidth: 1,
            yAxisID: 'y1',
            parsing: {
              yAxisKey: 'wt_veld'
            }
          }
        ]
      },
      options: {
        stacked: true,
        scales: {
          x: {
            ticks: {
              autoSkipPadding: 30
            }
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',

            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
        },
        elements: {
          point: {
            radius: function(ctx) {
              if (ctx.dataIndex == this.workingTimeStartsAtIndexValue || (ctx.datasetIndex == 1 && ctx.dataIndex == this.workingTimeEndsAtIndexValue)) {
                return 5
              }
              return 0
            }.bind(this)
          }
        },
        plugins: {
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                xMin: this.workingTimeStartsAtIndexValue,
                xMax: this.workingTimeStartsAtIndexValue,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
              },
              line2: {
                type: 'line',
                xMin: this.workingTimeEndsAtIndexValue,
                xMax: this.workingTimeEndsAtIndexValue,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
              }
            }
          }
        }
      }
    })

    // console.log(chart)

    $(this.formTarget).css({
      marginLeft: chart.chartArea.left - 8,
      width: chart.chartArea.width + 16
    })


    $(this.sliderTarget).ionRangeSlider({
      min: 0,
      max: this.sliderMaxValue,
      type: 'double',
      // step: 50,
      prettify: false,
      hasGrid: true,
      from: this.sliderFromValue,
      to: this.sliderToValue,
      onChange: function(data) {
        //this.sliderOnChange(data)
      }.bind(this),
      onFinish: function(data) {
        // $.rails.fire(obj.input[0], 'change')
        //this.sliderOnFinish(data)
      }.bind(this)
    })
  }

  // sliderOnChange(data) {
  //   console.log("sliderOnChange", data)
  // }
  //
  // sliderOnFinish(data) {
  //   console.log("sliderOnFinish", data)
  // }
}
