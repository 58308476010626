import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    startTimeUrl: String,
    jumpId: Number,
    progressText: String,
    completeText: String
  }
  static targets = [ "video", "setStartTimeButtonTime", "status" ]

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
    console.log('admin/jump controller', this.jumpIdValue)

    this.subscription = App.cable.subscriptions.create(
      {
        channel: "VideoConversionChannel",
        id: this.jumpIdValue,
      },
      {
        connected: this.channelConnected.bind(this),
        disconnected: this.channelDisconnected.bind(this),
        received: this.channelReceived.bind(this),
      }
    );

    if (this.hasSetStartTimeButtonTimeTarget) {
      this.updateSetStartTimeButtonTime()
    }
  }

  setStartTime() {
    const t = this.videoTarget.currentTime

    $.ajax({
      type: "PATCH",
      url: this.startTimeUrlValue,
      data: {
        jump: {
          start_time: t,
        }
      }
    })
  }

  setPlayerTime(e) {
    e.preventDefault()
    const time = e.target.getAttribute("data-value")
    this.videoTarget.currentTime = time
  }

  timeChanged(e) {
    this.updateSetStartTimeButtonTime()
  }

  updateSetStartTimeButtonTime() {
    this.setStartTimeButtonTimeTarget.textContent = this.videoTarget.currentTime.toFixed(1)
  }

  channelConnected() {
    console.log("connected to VideoConversionChannel")
  }

  channelDisconnected() {}

  channelReceived(data) {
    console.log("VideoConversionChannel received", data)
    this.updateProgress(data * 100)
  }

  updateProgress(progress) {
    let progressPercent = ''
    if (progress >= 100) {
      progressPercent = "100%"
    } else {
      progressPercent = Math.round(progress) + "%"
    }

    if (progress < 100) {
      this.statusTargets.forEach(function(el) {
        el.innerHTML = el.getAttribute("data-progress-text") + progressPercent
      })
    } else {
      this.statusTargets.forEach(function(el) {
        el.innerHTML = el.getAttribute("data-complete-text")
      })
    }
  }
}
